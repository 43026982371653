.login{
    height: 100vh;
    width: 100%;

}

.login_form{
    flex-direction: column;
    gap: 20px;
}

.label_log{
    width: 80px;
}

.login_box{
    box-shadow: 1px 2px 3px #8e2cf9;
    border-radius: 25px;
    padding: 20px;
}