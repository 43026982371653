.header {
    box-shadow: 1px 2px 3px #8e2cf9;

}

.header_container{
    height: 100px;
    padding: 15px 0;

}

.header_logo{
    height: 100%;
    width: auto;
}
.header_container.flex{
    justify-content: space-between
}


.site_grid{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
}
@media only screen and (max-width: 1401px) {
    .nav-item a{
        padding: 0.5em 0.4em;
    }
}
@media only screen and (max-width: 1201px) {
    .site_grid{
        grid-template-columns: repeat(2,1fr);

    }
}
@media only screen and (max-width: 770px) {
    .site_grid{
        grid-template-columns: repeat(1,1fr);

    }
}
