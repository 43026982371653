*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

.container{
  /* width: 1600px; */
  margin: 0 auto;
}

.bek{
  background-color: #8632e0;
  border-color: #8e2cf9;
}
.bek:hover{
  background: #6b1bc0 !important;
}
.bek:active{
  background-color: #a876dd !important;

}
.bek:focus-visible{
  background-color: #a876dd !important;

}
.masseg{
  position: absolute;
  right: 1%;
  top: 1%;
}
.login_inp:focus {
  border-color: #8e2cf9;
  outline: 0;
  box-shadow: 0 0 0 0.15rem #912bff38;
}
.serch{
  width: 20px;
  height: 20px;
}
.serch_inp{
  max-width: 600px;
}

.serch_inp:focus {
  border-color: #8e2cf9;
  outline: 0;
  box-shadow: 0 0 0 0.15rem #912bff38;
}

.warning {
  width: 20px;
  height: 20px;
  border: 1.5px solid #000;
  border-radius: 50%;
}
.status li{
  list-style: none;
}
.ms_groop{
  height: 58px;
}
.site_card_butBox{
  gap: 20px;
  justify-content: start;
}

@media only screen and (max-width: 430px) {
  body{
    min-width: 430px;

  }
}